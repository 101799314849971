import classes from './TermsAndConditions.module.scss'
import './style.css'

const TermsAndConditions = () => {
    return (<div className={classes.TermsAndConditions}>
        <header>
            <h1>Terms and conditions</h1>
        </header>
        <main>
            <div className="calibre" id="calibre_link-0">
                <p className="block_">EBONDS.Finance</p>
                <p className="block_1">Please note that investing in cryptocurrencies is highly speculative and carries a significant degree of risk. The market for cryptocurrencies is unregulated, and the value of digital assets can be extremely volatile. Investing in cryptocurrencies may not be suitable for all investors, and you should carefully consider your investment objectives, level of experience, and risk appetite before making any investment decisions. Additionally, past performance is not indicative of future results, and any investment in cryptocurrencies involves the risk of total loss of capital. We do not provide any investment advice, and all information on our platform/social media/ whitepaper is for informational purposes only. You should seek professional advice before making any investment decisions. When using this platform you also agree to the terms of use and service of metamask</p>
    
           


            </div>
        </main>

    </div>);
}

export default TermsAndConditions;